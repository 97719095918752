import React from 'react';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Typography,
	styled,
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { formatDate } from '../../utils/formatDate';
import { useNavigate, Link } from 'react-router-dom';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useSelector, useDispatch } from 'react-redux';
import { deleteReserva } from '../../redux/actions/client';

const TooltipStyled = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}));

export const ContactProfile = ({ dataClient }) => {
	const dispatch = useDispatch();
	const usuario = useAuth();
	const navigate = useNavigate();

	const lots = useSelector(state => state.clientDetail.lots);

	const employeeDetail = id => {
		if (usuario.user.id === dataClient?.User?.id) return;
		navigate(`/usuario/${id}`);
	};

	const handleDeleteReserva = e => {
		dispatch(deleteReserva(e));
	};

	return (
		<>
			<Card>
				<CardContent sx={{ position: 'relative' }}>
					<Box
						sx={{
							position: 'absolute',
							top: 10,
							right: 15,
							textTransform: 'capitalize',
							fontSize: 11,
							padding: 1,
							borderRadius: 2,
							color: 'white',
							background:
								dataClient.OriginDatum?.name === 'facebook'
									? '#395498'
									: dataClient.OriginDatum?.name === 'instagram'
									? 'linear-gradient(115deg, rgb(249, 206, 52), rgb(238, 42, 123), rgb(98, 40, 215))'
									: dataClient.OriginDatum?.name === 'whatsapp'
									? '#0dbb41'
									: dataClient.OriginDatum?.name === 'web'
									? '#4f772d'
									: 'red',
						}}
					>
						{dataClient.OriginDatum?.name}
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Avatar
							sx={{
								height: 80,
								mb: 2,
								width: 80,
							}}
						/>
						<Typography gutterBottom variant='h5' sx={{ textAlign: 'center' }}>
							{dataClient.name}
							<br />
							{dataClient.lastName ? ' ' + dataClient.lastName : null}
						</Typography>
						<Typography color='text.secondary' variant='body2' sx={{ textAlign: 'center' }}>
							{dataClient.province === 'bsas' ? 'Buenos Aires' : dataClient.province}
						</Typography>
						<Typography color='text.secondary' variant='body2'>
							{dataClient.locality ? dataClient.locality : 'Localidad: sin datos'}
						</Typography>
					</Box>
				</CardContent>
				<Divider />
				<CardActions>
					{dataClient?.User ? (
						<Button fullWidth variant='text' onClick={() => employeeDetail(dataClient.User.id)}>
							Asesor: {dataClient?.User?.fullName ? dataClient.User.fullName : 'Sin asesor'}
						</Button>
					) : (
						<Button fullWidth variant='text'>
							Asesor: {dataClient?.User?.fullName ? dataClient.User.fullName : 'Sin asesor'}
						</Button>
					)}
				</CardActions>
			</Card>
			<br />
			<Card>
				{lots.length === 0 ? (
					<div
						style={{
							display: 'flex',
							gap: 35,
							flexDirection: 'column',
							height: '300px',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography variant='caption'>Sin lotes asignados...</Typography>
					</div>
				) : (
					<CardContent sx={{ padding: 1 }}>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								height: '300px',
								maxHeight: '300px',
								overflowY: 'scroll',
								'&::-webkit-scrollbar': {
									width: '0.4em',
								},
								'&::-webkit-scrollbar-track': {
									borderRadius: '8px',
									bgcolor: '#e7e7e7',
									border: '1px solid #cacaca',
									boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
									// WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
								},
								'&::-webkit-scrollbar-thumb': {
									borderRadius: '8px',
									bgcolor: '#363636',
								},
							}}
						>
							<Typography sx={{ m: 1 }}>Lotes</Typography>
							<Divider sx={{ width: '100%' }} />

							{lots.map(e => {
								return (
									<Box
										key={e.id}
										sx={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
											padding: '15px',
										}}
									>
										<span style={{ display: 'flex', justifyContent: 'space-between' }}>
											<Link
												to={`/desarrollo/${e.DevelopmentId}`}
												style={{ textDecoration: 'none', color: 'inherit' }}
											>
												<Typography gutterBottom variant='h6'>
													{e.Development?.name ? e.Development.name : 'ERROR'}
												</Typography>
											</Link>
											<Typography gutterBottom variant='body2'>
												Lote: {e.numberLot ? e.numberLot : 'ERROR'}
											</Typography>
										</span>
										<span
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'baseline',
											}}
										>
											<Typography gutterBottom variant='caption'>
												{e.updatedAt ? formatDate(e.updatedAt) : 'ERROR'}
											</Typography>
											<span>
												<TooltipStyled
													title='Crear ficha'
													onClick={() =>
														navigate(`/ficha/${e.ContactId}/${e.DevelopmentId}/${e.numberLot}`)
													}
												>
													<NoteAddIcon sx={{ mt: 1.3, cursor: 'pointer' }} />
												</TooltipStyled>
												<TooltipStyled
													title='Eliminar reserva'
													onClick={() => handleDeleteReserva(e)}
												>
													<DeleteForeverIcon sx={{ mt: 1.3, cursor: 'pointer' }} />
												</TooltipStyled>
											</span>
										</span>
										<Divider sx={{ width: '100%' }} />
									</Box>
								);
							})}
						</Box>
					</CardContent>
				)}
			</Card>
		</>
	);
};
